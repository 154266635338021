import React, {useState} from 'react';
import Lightbox from 'react-image-lightbox';
import './Gallery.css';

function NewsGallery({ galleryList }) {

    const [galleryData, setGalleryData] = useState({
        tab1: 0,
        isOpen: false,
    });
    function mapGallery() {
        if (galleryList.length) {
            return galleryList.map((value, i) => {
                return (
                    <>
                        <div className="col-lg-4 col-md-6 col-12 pt--30" key={i}>
                            {galleryData.isOpen && (
                                <Lightbox
                                    enableZoom={false}
                                    animationDuration={300}
                                    animationOnKeyInput={true}
                                    mainSrc={galleryList[galleryData.tab1].src}
                                    nextSrc={galleryList[(galleryData.tab1 + 1) % galleryList.length]}
                                    prevSrc={galleryList[(galleryData.tab1 + galleryList.length - 1) % galleryList.length]}
                                    imageTitle={galleryList[galleryData.tab1].title}
                                    imageCaption={galleryList[galleryData.tab1].category}
                                    onCloseRequest={() => setGalleryData({ ...galleryData, isOpen: false })}

                                    onMovePrevRequest={() =>
                                        setGalleryData({
                                            ...galleryData, tab1: (galleryData.tab1 + galleryList.length - 1) % galleryList.length,
                                        })
                                    }
                                    onMoveNextRequest={() =>
                                        setGalleryData({
                                            ...galleryData, tab1: (galleryData.tab1 + 1) % galleryList.length,
                                        })
                                    }
                                />
                            )}
                            <div className="portfolio-style--3">
                                <div className=" thumbnail" >
                                    <a className="cursor-pointer" onClick={() => setGalleryData({ isOpen: true, tab1: i })}>
                                        <img className="w-100" src={value.src} alt="Gallery Images" />
                                    </a>
                                </div>
                                <div className="content cursor-pointer " onClick={() => setGalleryData({ isOpen: true, tab1: i })}>
                                    <p className="portfoliotype"></p>
                                    <h4 className="title"><a href="javascript:;">{value.title}</a></h4>
                                    <div className="portfolio-btn">
                                     
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )
            }
            )
        }
    }
    return (
        <>

            <div className="row row--5">
                {mapGallery()}
            </div>
        </>
    )
}

export default NewsGallery;
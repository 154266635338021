import React from 'react';
import './LandingSplitScreen.css'
import {Link } from 'react-router-dom'
import  Helmet  from '../component/common/Helmet';

function LandingSplitScreen(){
    return(
        <>  
        {/*
             <h1 className="landing-logo align-items-center  display-3">
            Liebiegovy <br/>výhledy
        </h1>
        */}
            <Helmet pageTitle="Liebiegovy Výhledy" />
            <img src="assets/images/logo/LiebiegovyVyhledyLogoWhite.png" alt="Liebiegovy Výhledy" className="landing-logo align-items-center"/>
            <div className="row g-0">
                <div className="col-md-6  g-0 ">
                    
                   
                    <div className="leftside  d-flex justify-content-center align-items-center">
                        <div className="content-landing-page d-flex justify-content-center align-items-center ">
                            <Link to={"vila-na-zatoci"} className=" fw-light split-heading btn btn-outline-dark shadow-lg "> VILA NA ZÁTOČÍ</Link>
                        </div>
                    </div>
                   
                </div>

                <div className="col-md-6  g-0">
                    
                  
                    <div className="rightside  d-flex justify-content-center align-items-center ">
                        <div className="content-landing-page d-flex justify-content-center align-items-center">
                            <Link to={"rezidence-klicperova"} className=" fw-light split-heading btn btn-outline-dark shadow-lg ">REZIDENCE KLICPEROVA</Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default LandingSplitScreen;
import {
    SET_SUBMIT_CONTACT_MESSAGE,
    SET_MAIN_CONTACT_SUBMITTING

} from './types';

import emailjs from 'emailjs-com';

// Handles submitting od morgage and customer data from morgage form
export function submitContactData(formData){
    return async (dispatch) => {
        dispatch({type: SET_MAIN_CONTACT_SUBMITTING, payload: true});
        var getUrl = window.location;
        var baseUrl = getUrl .protocol + "//" + getUrl.host + "/" + getUrl.pathname.split('/')[1];
        const contactData = {
            //Customer contact data
            customer_full_name: `${formData.firstName} ${formData.lastName}`,
            customer_email: formData.email,
            subject: formData.subject,
            message: !formData.message ? 'Zákazník nevyplnil zprávu': formData.message,
            web: window.location.origin.substring(window.location.origin.indexOf("//") +2, window.location.origin.length),
            webLink: baseUrl
        }
        /*
        console.log(contactData);
        setTimeout(() => dispatch({type: SET_MAIN_CONTACT_SUBMITTING, payload: false}), 2500);
        setTimeout(() => dispatch({type: SET_SUBMIT_CONTACT_MESSAGE, payload: 'error'}), 2500);
        */

        emailjs.send('service_30xg06c', 'template_bqyjbki', contactData, 'user_zIsu7IzIHTGx2JoId3yWZ')
        .then(function(response) {
            dispatch({type: SET_SUBMIT_CONTACT_MESSAGE, payload: 'success'});
            dispatch({type: SET_MAIN_CONTACT_SUBMITTING, payload: false});
            console.log('SUCCESS!', response.status, response.text);
         }, function(error) {
            dispatch({type: SET_SUBMIT_CONTACT_MESSAGE, payload: 'error'});
            dispatch({type: SET_MAIN_CONTACT_SUBMITTING, payload: false});
            console.log('FAILED...', error);
         });
    }
}
// React Required
import React, { Component } from 'react';
import ReactDOM from 'react-dom';

// Create Import File
import './index.scss';

import VilaNaZatoci from './home/LandingPage';

// Element Layout
import error404 from "./elements/error404";
import {Switch, Route  } from 'react-router-dom';
import LandingSplitScreen from './page-demo/LandingSplitScreen';
import ObchodniPodminky from './elements/ObchodniPodminky';
import OsobniUdaje from './elements/OsobniUdaje';
import Contact from './elements/Contact';
import PageDataWrapper from './PageDataWrapper';

class DraftRouter extends Component{
    render(){
        return(
            <PageDataWrapper>
                            <Switch>
                                <Route exact path={`${process.env.PUBLIC_URL}/draft-rk/`} component={LandingSplitScreen}/>
                                <Route exact path={`${process.env.PUBLIC_URL}/draft-rk/vila-na-zatoci`} component={VilaNaZatoci}/>
                                <Route exact path={`${process.env.PUBLIC_URL}/draft-rk/rezidence-klicperova`} component={VilaNaZatoci}/>
                                <Route exact path={`${process.env.PUBLIC_URL}/draft-rk/kontakt`} component={Contact}/>

                                <Route exact path={`${process.env.PUBLIC_URL}/draft-rk/obchodni-podminky`} component={ObchodniPodminky}/>
                                <Route exact path={`${process.env.PUBLIC_URL}/draft-rk/osobni-udaje`} component={OsobniUdaje}/>
                                <Route path={`${process.env.PUBLIC_URL}/draft-rk/404`} component={error404}/>
                                
                                <Route component={error404}/>
                            </Switch>
            </PageDataWrapper>
        )
    }
}

export default DraftRouter;
import {
    GET_NEWS_DATA,
    SET_LOADING_NEWS,

} from './types';
import blobStorageAPI from '../apis/blobStarageAPI';

export function getNews(emptyCacheStorage, latestVersionOfData) {
    return async (dispatch, getState) => {
        //Get texts for agent
        //https://storagecleverbasic.blob.core.windows.net/p4lgroup-data/SiteContent.json
        console.log('calling json file');
        dispatch({ type: SET_LOADING_NEWS, payload: true });
        await blobStorageAPI.get('KubelikovaNews.json')
            .then(response => {

                // If user doesnt have stored version it means he visits for the first time or cache was cleared and cache doesnt have to be cleared

                if (!latestVersionOfData) {
                    console.log("version of page not stored")
                    console.log("Clearing cache..")
                    emptyCacheStorage();
                    // Save current version
                    localStorage.setItem('latestVersionOfData', response.data.version);

                    dispatch({ type: GET_NEWS_DATA, payload: response.data });
                }
                // Or user already has latest version
                else if (latestVersionOfData === response.data.version) {
                    console.log("version of page matches")
                    console.log(latestVersionOfData);
                    dispatch({ type: GET_NEWS_DATA, payload: response.data });
                }
                // else if users version of page doesnt matches than we have to clear the cache to get new version
                else if (response.data.version !== latestVersionOfData) {
                    console.log("version of page doesnt match")
                    console.log(latestVersionOfData);
                    localStorage.removeItem('latestVersionOfData');

                    emptyCacheStorage();
                }
            })
            .catch(function (error) {
                console.log(error);

            });
        dispatch({ type: SET_LOADING_NEWS, payload: false });
    }
}
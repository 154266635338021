import React from "react";
import { useDispatch, useSelector } from 'react-redux';
import ContactForm from "./MainContactForm";
import {submitContactData} from '../../actions/contactActions';
import Loading from "./Loading";
import ContactSubmited from "./ContactSubmited";

function ContactTwo() {
    const dispatch = useDispatch();
    const appUI = useSelector(state => state.appUI);

    function handleSubmit(formData){
        dispatch(submitContactData(formData));
    }
    if(appUI.submittingMainContactData){
        return <Loading message="Odesílání Vaší zprávy.." />;
    }
    else if(appUI.submitContactMessage !== ''){
        return <ContactSubmited />;
    }
        return(
            <div className="contact-form--1">
                <div className="container">
                    <div className="row row--35 align-items-start justify-content-center">
                        <div className="col-lg-6 order-2 order-lg-1">
                            <div className="section-title text-left mb--50 text-center">
                                <h2 className="title">Kontaktujte Nás</h2>
                                <p className="description">Splňte si svůj sen o bydlení.</p>
                            </div>
                            <div className="form-wrapper">
                                <ContactForm handleFormSubmit={handleSubmit}/>
                            </div>
                        </div>
                        {/*
                            <div className="col-lg-6 order-1 order-lg-2">
                                <div className="thumbnail mb_md--30 mb_sm--30">
                                    <img src="/assets/images/about/about-6.jpg" alt="kontaktní obrázek"/>
                                </div>
                            </div>
                        */}
                     
                    </div>
                </div>
            </div>
        )
}
export default ContactTwo;
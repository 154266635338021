import React, { Component } from "react";
import { FaTwitter, FaInstagram, FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import { FiX, FiMenu, FiChevronLeft } from "react-icons/fi";
import { Link } from "react-router-dom";
import './Header.css';

import { Link as LinkScroll } from 'react-scroll';
const SocialShare = [
    { Social: <FaFacebookF />, link: 'https://www.facebook.com/' },
    { Social: <FaLinkedinIn />, link: 'https://www.linkedin.com/' },
    { Social: <FaInstagram />, link: 'https://www.instagram.com/' },
    { Social: <FaTwitter />, link: 'https://twitter.com/' },
]
class HeaderSecondary extends Component {
    constructor(props) {
        super(props);
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
        this.stickyHeader = this.stickyHeader.bind(this);


        //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
        window.addEventListener('load', function () {
            console.log('All assets are loaded');
        })

    }

    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }

    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open')
    }
    renderBackButton = () => {
        let currentURL = window.location.href;
        if (currentURL.endsWith('obchodni-podminky') || currentURL.endsWith('osobni-udaje')) {
            return <Link to={""} onClick={event => { window.close(); event.preventDefault(); }}> Zavřít podmínky </Link>;
        }
        return (
            <>
                <Link to={''} ><FiChevronLeft /> Zpět </Link>
            </>
        )
    }
    renderContactButton = () => {
        let currentURL = window.location.href;
        if (currentURL.endsWith('obchodni-podminky') || currentURL.endsWith('privacy-policy')) {
            return null;
        }

        return (
            <>
                <Link
                    to={`/kontakt`}
                    smooth={true} offset={-100}
                    className="rn-btn-contact cursor-btn" >
                    <span>Kontakt</span>
                </Link>
            </>
        )
    }
    stickyHeader() { }

    render() {
        let currentURL = window.location.href;


        let { logo, color = 'default-color' } = this.props;
        window.addEventListener('scroll', function () {
            if ((window.location.href.includes('/kontakt') ||
                window.location.href.includes('/novinky') ||
                window.location.href.includes('/obchodni-podminky') ||
                window.location.href.includes('/osobni-udaje')) &&
                document.querySelector('.header--fixed')) {
                var value = window.scrollY;
                if (value > 50) {
                    document.querySelector('.header--fixed').classList.add('sticky')
                } else {
                    document.querySelector('.header--fixed').classList.remove('sticky')
                }
            }
        });

        var elements = document.querySelectorAll('.has-droupdown > a');
        for (var i in elements) {
            if (elements.hasOwnProperty(i)) {
                elements[i].onclick = function () {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }


        return (
            <header className={`header-area header-style-two header--fixed default-color`}>
                <div className=" header-wrapper header-wrapper-side-page">
                    <div className="header-left d-flex align-items-center">
                        <div className="logo">
                            {this.renderBackButton()}
                        </div>
                    </div>
                    <div className="header-right">
                        <div className="header-btn">
                            {this.renderContactButton()}
                        </div>
                    </div>
                </div>
            </header>
        )
    }
}
export default HeaderSecondary;
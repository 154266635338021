import React from 'react';
import './LoadingPage.css';

function LoadingPage({message}){
   

    return(
        <>
        <div className="loading loading03">
            <span >P</span>
            <span >R</span>
            <span >O</span>
            <span >J</span>
            <span >E</span>
            <span >K</span>
            <span >T</span>
            <span >&nbsp;</span>
            <span >K</span>
            <span >U</span>
            <span >B</span>
            <span >E</span>
            <span >L</span>
            <span >Í</span>
            <span >K</span>
            <span >O</span>
            <span >V</span>
            <span >A</span>
      </div>
      </>
    )
}

export default LoadingPage;
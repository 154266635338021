import React from 'react'

function PlaceholderDraft(){
    return(
        <>
            <nav className="navbar navbar-light bg-light">
                <div className="navbar-brand ">
                    <div className="container pl-4 ml-5"> <img
                            src="https://appservice.azureedge.net/images/app-service/v3/microsoft_azure_logo.svg" width="270"
                            height="108" alt=""/> </div>
                </div>
            </nav>
            <div className="container-fluid container-height mr-2">
                <div className="pt-10 pb-10 mt-10 mb-10 d-xxs-none d-xs-none d-sm-none d-md-none d-lg-block d-xl-block"
                    style={{height: "20px", width: "100%", clear: "both"}}></div>
                <div className="row">
                    <div className="row col-xs-12 col-sm-12 d-block d-lg-none d-xl-none d-md-block d-sm-block d-xs-block">
                        <div className="text-center"> <img src="https://appservice.azureedge.net/images/app-service/v3/generic.svg"/>
                        </div>
                    </div>
                    <div
                        className=" extra-pl-small-scr offset-xl-1 offset-lg-1 offset-md-2 offset-sm-2 offset-xs-4 col-xl-5 col-lg-5 col-md-10 col-sm-11 col-xs-11 div-vertical-center">
                        <div className="container-fluid">
                            <div className="row">
                                <h2 id="heyDevelopers">Hey, App Service developers!</h2>
                            </div><br/>
                            <div className="row">
                                <h4 id="upRunning">Your app service is up and running.</h4>
                            </div>
                            <div className="row">
                                <h4 id="nextSteps">Time to take the next step and deploy your code.</h4>
                            </div>
                            <div className="row info-mg-top">
                                <p className=" pl-0 col-md-6 col-sm-12 info-mg-top"><span id="codeReady">Have your code
                                        ready?</span><br/><span id="useDCenter"> Use deployment center to get code published from
                                        your client or setup continuous deployment.</span><br/><a id="depCenterLink1"
                                        href="https://go.microsoft.com/fwlink/?linkid=2057852"><button
                                            className="btn btn-primary btn-mg-top" type="submit" id="deplCenter">Deployment
                                            Center</button></a></p>
                                <p className="pl-0 offset-md-1 col-md-5 col-sm-12 info-mg-top"> <span id="codeNotReady">Don't have
                                        your code yet?</span><br/><span id="followQS">Follow our quickstart guide and you'll have
                                        a full app ready in 5 minutes or less.</span> <br/><a
                                        href="https://go.microsoft.com/fwlink/?linkid=2084231"><button
                                            className="btn btn-primary btn-mg-top" type="submit"
                                            id="quickStart">Quickstart</button></a></p>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-5 col-lg-5 col-md-12 d-none d-lg-block">
                        <div className="text-center"><img src="https://appservice.azureedge.net/images/app-service/v3/generic.svg"/>
                        </div>
                    </div>
                    <div className="col-xl-1 col-lg-1 col-md-1"></div>
                </div>
            </div>
        </>
    )
}
export default PlaceholderDraft;
import React from 'react';
import {FaTwitter ,FaInstagram ,FaFacebookF , FaLinkedinIn} from "react-icons/fa";

const SocialShare = [
    //{Social: <FaFacebookF /> , link: 'https://www.facebook.com/P4Lreality/'},
   // {Social: <FaInstagram /> , link: 'https://www.instagram.com/jakub__hanus/'},
]

const FooterTwo = () => {
    return (
        <div className="footer-style-2 ptb--30 bg_image bg_image--1" data-black-overlay="6">
            <div className="wrapper plr--50 plr_sm--20">
                <div className="row align-items-center justify-content-between">
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                        <div className="inner">
                            <div className="logo text-center text-sm-left mb_sm--20">
                              
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                        <div className="inner text-center">
                       
                            <div className="text">
                                <p>Copyright © 2022 Souz s.r.o. All Rights Reserved.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                      
                    </div>
                </div>
            </div>
        </div>
    )
}
export default FooterTwo;
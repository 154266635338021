import React, { useState } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from 'react-modal-video';
import { slickDot } from "../page-demo/script";
import ScrollAnimation from 'react-animate-on-scroll';
import { useSelector } from 'react-redux';
import { FiClock, FiUser } from "react-icons/fi";
import ScrollToTop from '../component/common/ScrollTop';
import Header from "../component/header/HeaderSecondary";
import Footer from "../component/footer/FooterTwo";
import Loading from "./Loading";
import Slider from "react-slick";
import { useParams } from "react-router-dom";
import _ from 'lodash';
import './NewsDetail.css';
import NewsGallery from "../component/gallery/NewsGallery";

function NewsDetail() {

    const appUI = useSelector(state => state.appUI);
    const [videoOpen, setVideoOpen] = useState(false);
    let { title } = useParams();
    let newsArticle;
    let AllPostList = [];
    let OtherPostList = []


    if (appUI.news.length) {
        appUI.news.forEach(article => {
            if (article.title === title) {
                newsArticle = article;
            }
            else {

                OtherPostList.push(article);
            }
        });
    }
    function renderNews() {

        if (OtherPostList.length) {

            slickDot.slidesToShow = OtherPostList.length;
            return (
                <>
                    <ScrollAnimation animateIn="fadeInRight" delay={500} duration={3} animateOnce={true}>
                        <div className="rn-slick-dot">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="slick-space-gutter--15 slickdot--20">
                                        <Slider {...slickDot}>
                                            {OtherPostList.map((value, index) => (
                                                <div className="portfolio" key={index}>
                                                    <div className="thumbnail-inner">
                                                        <div style={{ backgroundImage: `url(${value.thumbnailSRC})` }} className={`thumbnail `}></div>
                                                        <div style={{ backgroundImage: `url(${value.thumbnailSRC})` }} className={`bg-blr-image `}></div>
                                                    </div>
                                                    <div className="content">
                                                        <div className="inner">
                                                            <p>{value.category}</p>
                                                            <h4><a href="/portfolio-details">{value.title}</a></h4>
                                                            <div className="portfolio-button">
                                                                <a className="rn-btn text-white" href={`${window.location.origin}/clanky/${value.title}`}>Přěčíst</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </Slider>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ScrollAnimation>
                </>
            )
        }

        else {
            return (
                <>
                    <div className="row">
                        <Loading message="Načítání novinek.." />
                    </div>
                </>
            )
        }
    }
    if (!newsArticle)
        return null;

    return (
        <React.Fragment>
            <PageHelmet pageTitle='Novinky - Svítkov' />
            <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />

            {/* Start Breadcrump Area */}
            <div style={{ backgroundImage: `url(${newsArticle.thumbnailSRC})` }} className="rn-page-title-area pt--120 pb--190 bg_image " data-white-overlay="8">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="blog-single-page-title text-center pt--100">
                                <h2 className="title theme-gradient text-center ">{newsArticle.title}</h2>
                                <ul className="blog-meta d-flex justify-content-center align-items-center">
                                    <li className="sub-text "><FiClock />{newsArticle.date}</li>
                                    <li className="sub-text "><FiUser />{newsArticle.autor}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* End Breadcrump Area */}

            {/* Start Blog Details */}
            <div className="rn-blog-details pt--110 pb--50 bg_color--1">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="inner-wrapper">
                                <div className="inner" dangerouslySetInnerHTML={{ __html: newsArticle.text }}>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Start Portfolio Area  */}
            {newsArticle.gallery.length ?
                < div className="creative-portfolio-wrapper pt--15 pb--70" id="gallery">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="section-title text-center service-style--3 mb--30">
                                <h2 className="title ">Galerie</h2>
                            </div>
                        </div>
                    </div>
                    <div className="container plr--10">
                        <NewsGallery galleryList={newsArticle.gallery} />
                    </div>
                </div>
                :
                null
            }

            {/* End Portfolio Area  */}

            {/* Start Video Area  */}
            {
                !newsArticle.videoData.channel == "" ?

                    <div className="rn-section ptb--120 bg_color--1 line-separator" id="video">
                        <div className="container">
                            <div className="row sercice-details-content align-items-center">
                                <div className="col-lg-12">
                                    <div className="thumb position-relative">
                                        {
                                            !newsArticle.videoData.thumbnailSRC == "" && !newsArticle.videoData.thumbnailALT == "" ?
                                                <img className="w-100" src={newsArticle.videoData.thumbnailSRC} alt={newsArticle.videoData.thumbnailALT} />
                                                : null
                                        }
                                        <ModalVideo channel={newsArticle.videoData.channel} isOpen={videoOpen} autoplay={true} videoId={newsArticle.videoData.videoId} onClose={() => setVideoOpen(false)} />
                                        <button className="video-popup position-top-center" onClick={() => setVideoOpen(true)}><span className="play-icon"></span></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : null
            }
            {/* End Video Area  */}
            {/* End Blog Details */}
            {
                appUI.news.length > 1 ?

                    <div id="news" className="fix">
                        <div className="rn-blog-area ptb--120 bg_color--5 mb-dec--30">
                            <div className="container">
                                <div className="row align-items-end">
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                                        <div className="section-title text-center">
                                            <ScrollAnimation animateIn="fadeInUp" duration={2} animateOnce={true}>
                                                <h2>Další Novinky</h2>
                                            </ScrollAnimation>
                                        </div>
                                    </div>
                                </div>
                                {renderNews()}
                            </div>
                        </div>
                    </div>
                    : null
            }
            {/* Start Back To Top */}
            <div className="backto-top">
                <ScrollToTop />
            </div>
            {/* End Back To Top */}

            <Footer />

        </React.Fragment >
    )
}
export default NewsDetail;
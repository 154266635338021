import React from 'react';
import { object } from 'prop-types';
import { Field, reduxForm } from 'redux-form'
import { useDispatch } from 'react-redux';

import './Contact.css';

import { Link } from "react-router-dom";
import { SET_SHOW_MODAL_LEGAL } from '../../actions/types';

function CustomCheckBox  ({
    input: { value, onChange, ...input },
    meta: { touched, error },
    name,
    inline,
    label,
    feedback,
    privacyAgreement,
    ...rest
  }) { 
    const dispatch = useDispatch();
    let isInline = inline ? 'form-check-inline' : '';
    let isInvalid = '';

    if(touched && error ){
      isInvalid = 'is-invalid';
    } else if(touched && !error){
      isInvalid = '';

    }
    function renderLabel(){
      if(privacyAgreement){
        return (
          <>
            Souhlasím s <span  className="fake-link-span" onClick={() => dispatch({type: SET_SHOW_MODAL_LEGAL, payload: {show: true, legalType: 'conditions'}})}>obchodními podmínkami</span> a <span  className="fake-link-span" onClick={() => dispatch({type: SET_SHOW_MODAL_LEGAL, payload: {show: true, legalType: 'private'}})} >ochranou osobních údajů</span> 
          </>
        );
      }
      return label;
    }
    return (
        <>
          <div className={`form-check ${isInline} ${isInvalid}`}>
            <input className={`form-check-input ${isInvalid} custom-checkbox`} type="checkbox" id={name}   {...input}
            {...rest} onChange={e => onChange(e.target.checked)}/>
            <label  className="form-check-label" htmlFor={name}>{renderLabel()} </label>
            <div className="invalid-feedback">
              {feedback}
            </div>
          </div>
        </>
       
  )};
  
  CustomCheckBox.propTypes = {
    input: object.isRequired,
    meta: object.isRequired
  };
  
  CustomCheckBox.defaultProps = {
    input: null,
    meta: null
  };
  
  export default props => <Field {...props} component={CustomCheckBox} />;

  
        {/*
           <Form.Check
        {...input}
        {...rest}
        defaultChecked={!!value}
        onChange={e => onChange(e.target.checked)}
        type="checkbox"
        inline={inline}
        isInvalid={touched && error}
        isValid={touched && !error}
      />
        */}
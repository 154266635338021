import React, { useState, useEffect } from "react";
import { withRouter } from 'react-router-dom';
import { getNews } from './actions/newsActions';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import LoadingPage from "./component/common/LoadingPage";
import Preload from 'react-preload';
import { useClearCache } from 'react-clear-cache';


const PageDataWrapper = (props) => {
    const { emptyCacheStorage } = useClearCache();
    // Added loaded because of safari browser, otherwise loading screen is not displayed
    const [loaded, setLoaded] = useState(false);
    const dispatch = useDispatch();
    var loadingIndicator = (<LoadingPage />);
    let images = [
        "/assets/images/bg/bg-image-29.jpg",
        'https://storagecleverbasic.blob.core.windows.net/p4lgroup-data/kubelikova/portfolio-01.png',
        'https://storagecleverbasic.blob.core.windows.net/p4lgroup-data/kubelikova/portfolio-02.png',
       'https://storagecleverbasic.blob.core.windows.net/p4lgroup-data/kubelikova/portfolio-03.png',
    ];
    useEffect(() => {
        const latestVersionOfData = localStorage.getItem('latestVersionOfData');
        dispatch(getNews(emptyCacheStorage, latestVersionOfData));
    }, []);

    //Safari browser fix
    window.onload = function () {
        setLoaded(true);
    };
    if (loaded) {
        return (
            <>
                {/* 
               <LoadingPage />
            */}

                <Preload
                    loadingIndicator={loadingIndicator}
                    images={images}
                    autoResolveDelay={12000}
                    resolveOnError={true}
                    mountChildren={true}
                >
                    {props.children}
                </Preload>
            </>
        )
    }
    else {
        return <LoadingPage />;
    }


}
export default withRouter(PageDataWrapper);
import React from "react";
import { FaPhoneAlt,} from "react-icons/fa";
import { MdEmail,} from "react-icons/md";

let data = [
    {
        images: 'https://storagecleverbasic.blob.core.windows.net/p4lgroup-data/kubelikova/team-01.jpg',
        title: 'Lukáš Berka',
        designation: 'Investiční Specialista',
        contact: {
            phone: {
                icon: <FaPhoneAlt size={10}/>,
                value: '725 459 571'
            },
            email: {
                icon: <MdEmail />,
                value: 'lukas.berka@p4life.cz'
            },
        },
    },
    {
        images: 'https://storagecleverbasic.blob.core.windows.net/p4lgroup-data/kubelikova/team-02.jpg',
        title: 'Jakub Hanuš',
        designation: 'Realitní Makléř',
        contact: {
            phone: {
                icon: <FaPhoneAlt size={10}/>,
                value: '604 789 834'
            },
            email: {
                icon: <MdEmail />,
                value: 'jakub.hanus@p4life.cz'
            },
        },
    },
    {
        images: 'https://storagecleverbasic.blob.core.windows.net/p4lgroup-data/kubelikova/team-03.jpg',
        title: 'Lucie Berková',
        designation: 'Hypoteční Specialista',
        contact: {
            phone: {
                icon: <FaPhoneAlt size={10}/>,
                value: '732 265 505'
            },
            email: {
                icon: <MdEmail />,
                value: 'lucie.berkova@p4life.cz'
            },
        },
    },
   
  


];

export default data;
import React, { Component } from "react";

class BrandTwo extends Component{
    render(){
        return(
            <React.Fragment>
                <ul className="brand-style-2">
                    <li>
                        <a href="https://www.p4life-rk.cz/" target="_blank"><img src="/assets/images/brand/PartnerLogoP4LGROUP_Reality.png" alt="Logo P4LGROUP Reality"/></a>
                    </li>
                    <li>
                        <a href="https://www.p4l-group.cz/#investments" target="_blank"><img src="/assets/images/brand/PartnerLogoP4LGROUP_Investments.png" alt="Logo P4LGROUP Investice"/></a>
                    </li>
                    <li>
                        <a href="https://p4life.cz/" target="_blank"><img src="/assets/images/brand/PartnerLogoP4LGROUP_Finance.png" alt="Logo P4LGROUP Finance"/></a>
                    </li>
                 
                    <li>
                        <a href="https://www.clever-technology.com/" target="_blank"><img src="/assets/images/brand/CleverLogoGold.png" alt="Clever Solutions Technology"/></a>
                    </li>

                  
                </ul>
            </React.Fragment>
        )
    }
}
export default BrandTwo;
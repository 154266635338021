import React, { Component } from 'react';
import ScrollToTop from '../component/common/ScrollTop';
import { FiChevronUp } from "react-icons/fi";
import Footer from "../component/footer/FooterTwo";
import { Link } from "react-router-dom";
import '../component/common/ScrollTop.css';
 class error404 extends Component {
    
    render() {
        return (
            <>
               
                {/* Start Page Error  */}
                <div className="error-page-inner bg_color--4">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner">
                                    <h1 className="title theme-gradient">404!</h1>
                                    <h3 className="sub-title">Stránka nenalezena</h3>
                                    <span>Hledaná stránka nejspíše neexistuje.</span>
                                    <div className="error-button">
                                        <Link className="rn-button-style--2 btn-solid no-padding" to={""}>Zpět na Domovskou stránku</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Page Error  */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop />
                </div>
                {/* End Back To Top */}
                
                <Footer /> 
            </>
        )
    }
}
export default error404;

import React from 'react';
import GoogleMapReact from 'google-map-react';
import './Map.css'

const MapMark = () =>   <div className="logo-map-background"><img src="assets/images/logo/KubelikovaLogo.png" alt="Novostavba Svítkov" className="logo-map black-dot  "/></div>;

function LocationMap(){
    return(
        <>
            <div style={{ height: '650px', width: '100%' }}>
                        <GoogleMapReact
                        bootstrapURLKeys={{ key: "AIzaSyAjn549kNeDFM8Fxh_Wqy-uWUvr0OgWGDc" }}
                        defaultCenter={ {lat: 50.7503,lng: 15.0323}}
                        defaultZoom={11}
                        >
                        <MapMark
                            lat={50.7503}
                            lng={15.0323}
                            text="Projekt Kubelíkova"
                        />
                        </GoogleMapReact>
                    </div>
        </>
    )
}

export default LocationMap;
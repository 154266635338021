import React from 'react';
import Spinner from 'react-bootstrap/Spinner';
import './Loading.css';

function Loading({message}){
    return(
        <div className="loading-wrapper-news ">
            <div>
            <span className="d-flex justify-content-center text-center "><h4 className="fw-light text-center">{message}</h4></span><br/>
            <span className="d-flex justify-content-center">
                <Spinner className="spinner-border" animation="border" role="status" size="lg">
                </Spinner>
            </span>
            </div>
           
        </div>
    )
}

export default Loading;